import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HomeSlide from '../../../components/Home/Slide';
import HomePromotion from '../../../components/Home/Promotion';
import HomeBlog from '../../../components/Home/Blog';
import HomeAbout from '../../../components/Home/About';
import HomeReview from '../../../components/Home/Review';
import HomeBanner from '../../../components/Home/Banner';
import HomeProduct from '../../../components/Home/Product';

export default function PageHome() {

    return (
        <>
            <HomeBanner />
            <div className="box-main-content">
                <HomeSlide />
                <HomeProduct />
                {/* <HomePromotion /> */}
                <HomeBlog />
                <HomeAbout />
            </div>
            <HomeReview />
        </>
    )
}
